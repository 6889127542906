import React from 'react';

const SessionContext = React.createContext(null);

export const withSession = Component => props => (
  <SessionContext.Consumer>
    {authUser => <Component {...props} authUser={authUser} />}
  </SessionContext.Consumer>
);

export default SessionContext;
