module.exports = {
  fr: {
    path: 'fr',
    locale: 'Français',
    display: 'FR',
    default: true,
  },
  en: {
    path: 'en',
    locale: 'English',
    display: 'EN',
  },
};
