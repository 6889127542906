import React from 'react';

export const defaultContextValue = {
  query: '',
  setQuery: () => {
  },
};

// Global state for entire website (all pages).
const SearchContext = React.createContext(defaultContextValue);

export const withApplication = Component => props => (
  <SearchContext.Consumer>
    {({ query, setQuery }) => (
      <Component
        {...props}
        query={query}
        setQuery={setQuery}
      />
    )}
  </SearchContext.Consumer>
);

export default SearchContext;
