import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
//import FormControl from 'react-bootstrap/es/FormControl';
import Form from 'react-bootstrap/Form';
import { navigate } from 'gatsby';
import InputGroup from 'react-bootstrap/InputGroup';
import { injectIntl } from 'react-intl';

// Messages.
import en from '../i18n/en.json';
import fr from '../i18n/fr.json';
import { withApplication } from '../services/search/context';

const jsonFilesText = { en, fr };

class SearchMenu extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    // this.state = {
    //   query: '',
    // };
  }

  handleClick(e) {
    e.preventDefault();

    const { intl: { locale } } = this.props;

    // const { query } = this.state;
    const { query } = this.props;

    // const regex = new RegExp('^(?!\\s).*$');

    // if (!query || query.length > 50 || regex.test(query)) {
    // Limit search input to 50 characters.
    // Test if query contains only blank characters.
    if (!query || query.length > 50 || query.trim().length === 0) {
      // this.setState({
      //   results: [],
      //   query,
      // });

      return;
    }

    // console.log(window.__LUNR__);
    //
    // const lunrIndex = window.__LUNR__[locale];
    //
    // const queryResults = lunrIndex.index.search(query);
    // const results = queryResults.map(({ ref }) => lunrIndex.store[ref]);

    // this.setState({
    //   query: '',
    // });

    // Passed through the query and make the search in the search page only.
    if (locale === 'fr') {
      navigate('/fr/search/', { state: { query } });
    } else {
      navigate('/search/', { state: { query } });
    }
  }

  handleChange(e) {
    // this.setState({
    //   query: e.target.value.toLowerCase()
    //     .trim(),
    // });

    // Update global state to keep value when navigating to another page.
    const { setQuery } = this.props;

    setQuery(e.target.value);
  }

  render() {
    // const { query } = this.state;

    const {
      intl: { locale },
      searchResults,
      query,
    } = this.props;

    if (searchResults !== undefined && searchResults.length === 0) {
      return (
        <Form.Row>
          <Col md={5}>
            <Form>
              <InputGroup>
                <Form.Control
                  type="search"
                  maxLength="50"
                  placeholder={jsonFilesText[locale].searchInputHint}
                  aria-label={jsonFilesText[locale].searchFormInputLabel}
                  onChange={this.handleChange}
                  value={query}
                />
                <InputGroup.Append>
                  <Button
                    id="not-found-search-page-form-button"
                    type="submit"
                    className="shadow-none"
                    variant="dark"
                    aria-label={jsonFilesText[locale].searchButtonLabel}
                    onClick={this.handleClick}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Col>
        </Form.Row>
      );
    }

    return (
      <Form
        id="search-form"
        className="nav-item d-flex align-items-center justify-content-center
                   desktop-absolute-position pt-4 pt-lg-0 pb-4 pb-lg-0"
      >
        <Form.Control
          id="search-input"
          type="search"
          maxLength="50"
          placeholder={jsonFilesText[locale].searchInputHint}
          onChange={this.handleChange}
          value={query}
        />
        <Button
          type="submit"
          className="ml-2 border-0 shadow-none nav-item"
          variant="outline-light"
          onClick={this.handleClick}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </Form>
    );
  }
}

SearchMenu.propTypes = {
  onSearchLaunch: PropTypes.func,
  intl: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
};

SearchMenu.defaultProps = {
  onSearchLaunch: null,
};

export default injectIntl(withApplication(SearchMenu));
