import React from 'react';
import { Helmet } from 'react-helmet';
import * as PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultLanguage
      }
    }
  }
`;

export default function SEO({ title, description, language }) {
  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultTitle,
            titleTemplate,
            defaultDescription,
            defaultLanguage,
          },
        },
      }) => {
        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          language: language || defaultLanguage,
        };

        return (
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            {/* <meta charSet="utf-8" /> */}
            <meta name="description" content={seo.description} />
            <html lang={seo.language} />
          </Helmet>
        );
      }}
    />
  );
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  language: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  language: null,
};
