import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { navigate } from 'gatsby';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';

import { withFirebase } from '../services/firebase';
import LocalizedLink from './LocalizedLink';

class LogoutButton extends Component {
  constructor(props) {
    super(props);

    this.handleSignOutButtonClick = this.handleSignOutButtonClick.bind(this);
  }

  handleSignOutButtonClick() {
    const { firebase, location } = this.props;

    firebase.doSignOut()
      .then(() => {
        // Redirect to login when user disconnected.
        // When we are on profile page (protected page), we don't need to add the page to history.
        // Otherwise, we will have two login pages.
        if (location.pathname === '/app/profile/') {
          navigate('app/login/', { replace: true });
        } else {
          navigate('app/login/');
        }
      });
  }

  render() {
    return (
      <Button
        type="submit"
        size="sm"
        variant="outline-primary"
        onClick={this.handleSignOutButtonClick}
        // className="px-4 px-lg-2 py-2 py-lg-1"
      >
        <FormattedMessage id="logoutButton" />
      </Button>
    );
  }
}

LogoutButton.propTypes = {
  firebase: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withFirebase(LogoutButton);
