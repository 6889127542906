import * as PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';

import locales from '../constants/locales';

import allSlugs from '../i18n/slugs.json';

function LocalizedLink({ to, intl: { locale }, ...props }) {
  // const path = locales[locale].default ? to : `/${locale}${to}`;
  const path = locales[locale].default ? to : `/${locale}${allSlugs[locale][to]}`;

  return (
    <Link {...props} to={path} />
  );
}

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LocalizedLink);
