import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopyright, faAngleDoubleUp, faUser, faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  graphql,
  StaticQuery,
  navigate,
} from 'gatsby';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Img from 'gatsby-image';
import Tooltip from 'react-bootstrap/Tooltip';
// import { addLocaleData, FormattedMessage, IntlProvider } from 'react-intl';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { config } from '@fortawesome/fontawesome-svg-core';
import locales from '../constants/locales';

// Locale data.
// import enData from 'react-intl/locale-data/en';
// import frData from 'react-intl/locale-data/fr';
import allPatterns from '../i18n/patterns.json';

// Messages.
import en from '../i18n/en.json';
import fr from '../i18n/fr.json';

// DatArt logo
import datArtLogo from '../../content/assets/datArtLogo.svg';

import '../scss/custom.scss';
// This ensures that the icon CSS is loaded immediately before attempting to render icons.
import '@fortawesome/fontawesome-svg-core/styles.css';
import { withSession } from '../services/session/context';

import LocalizedLink from './LocalizedLink';
import SearchMenu from './SearchMenu';
import LogoutButton from './LogoutButton';
import { forEach } from 'react-bootstrap/cjs/utils/ElementChildren';
// import logoDatArt from '../../content/assets/datArtLogo.png';

// Prevent fontawesome from dynamically adding its css since we did it manually above.
config.autoAddCss = false;

const jsonFilesText = {
  en,
  fr,
};

// addLocaleData([...enData, ...frData]);

// function Layout({ location, title, children }) {
//   return (
//     <div>
//       <header className="sticky-top">
//         <nav className="navbar navbar-expand-md bg-dark navbar-dark">
//           <a
//             className="navbar-brand"
//             href="http://localhost:8001"
//           >
//             {title}
//           </a>
//           <button
//             type="button"
//             className="navbar-toggler"
//             // data-toggle="collapse"
//             // data-target="#collapsibleNavbar"
//           >
//             <span className="navbar-toggler-icon" />
//           </button>
//           <div
//             className="collapse navbar-collapse"
//             id="collapsibleNavbar"
//           >
//             <ul className="navbar-nav">
//               <li className="nav-item">
//                 <a
//                   className="nav-link"
//                   href="http://localhost:8001"
//                 >
//                   Link
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a
//                   className="nav-link"
//                   href="http://localhost:8001"
//                 >
//                   Link
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a
//                   className="nav-link"
//                   href="http://localhost:8001"
//                 >
//                   Link
//                 </a>
//               </li>
//               <li className="nav-item dropdown">
//                 <a
//                   className="nav-link dropdown-toggle"
//                   href="http://localhost:8001"
//                   id="navbardrop"
//                   // data-toggle="dropdown"
//                 >
//                   Dropdown link
//                 </a>
//                 <div className="dropdown-menu">
//                   <a
//                     className="dropdown-item"
//                     href="http://localhost:8001"
//                   >
//                     Link 1
//                   </a>
//                   <a
//                     className="dropdown-item"
//                     href="http://localhost:8001"
//                   >
//                     Link 2
//                   </a>
//                   <a
//                     className="dropdown-item"
//                     href="http://localhost:8001"
//                   >
//                     Link 3
//                   </a>
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </nav>
//       </header>
//       <div className="container">
//         <main>{children}</main>
//         <footer>{location.pathname}</footer>
//       </div>
//     </div>
//   );
// }

// export const query = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
// #    datArtLogo: file(relativePath: {regex: "/datArtLogo/"}) {
// #      childImageSharp {
// #        fluid {
// #          ...GatsbyImageSharpFluid_noBase64
// #        }
// #      }
// #    }
//     allMarkdownNodes: allMarkdownRemark {
//       edges {
//         node {
//           frontmatter {
//             date
//             description
//             lang
//             title
//             url
//           }
//           html
//           fields {
//             fileName
//           }
//         }
//       }
//     }
//   }
// `;

// TODO: Add const values
// TODO: Add correct proptypes
// TODO: Construct more complex state (levels)
// TODO: Optimize icons loading
class LayoutBase extends Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleNavDropdownLanguageSelect = this.handleNavDropdownLanguageSelect.bind(this);
    this.handleGoToTopButtonClick = this.handleGoToTopButtonClick.bind(this);

    this.searchMenuNode = React.createRef();
    this.searchToggleNode = React.createRef();

    this.state = {
      displayGoToTopButton: false,
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleScroll() {
    const { displayGoToTopButton } = this.state;

    // Check if we have to display the go to top button based on the window width (breakpoint lg
    // with bootstrap) and the position of the vertical cursor.
    if (!displayGoToTopButton && window.innerWidth >= 992 && window.pageYOffset > 300) {
      this.setState({ displayGoToTopButton: true });

      return;
    }

    if (displayGoToTopButton && window.pageYOffset <= 300) {
      this.setState({ displayGoToTopButton: false });
    }
  }

  handleResize() {
    const { displayGoToTopButton } = this.state;

    // Check if we have to display the go to top button based on the position of the vertical
    // cursor and the window width (breakpoint lg with bootstrap).
    if (!displayGoToTopButton && window.innerWidth >= 992 && window.pageYOffset > 300) {
      this.setState({ displayGoToTopButton: true });

      return;
    }

    if (displayGoToTopButton && window.innerWidth < 992) {
      this.setState({ displayGoToTopButton: false });
    }
  }

  /* isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? { className: 'navlink-active navlink' }
      : { className: 'navlink' }
  };

  PartialNavLink = props => (
    <Link getProps={isPartiallyActive} {...props}>
      {props.children}
    </Link>
  ); */

  handleNavDropdownLanguageSelect(eventKey) {
    const { location, contactPageLanguageChange, pageSlugs } = this.props;

    // // If the user changes the language on contact page, we have to keep form values.
    // if (contactPageLanguageChange !== undefined) {
    //   // Inform parent (contact page) that the user wants to change the language.
    //   contactPageLanguageChange(eventKey);
    //
    //   return;
    // }

    // Get corresponding slug (URL) to go to the translated page.
    const destinationPage = pageSlugs
      .filter((pageSlug) => Object.prototype.hasOwnProperty.call(pageSlug, eventKey))[0][eventKey];

    navigate(destinationPage);

    // if (eventKey === 'fr') {
    //   // Go to the french version of the current page.
    //   navigate(`/fr${location.pathname}`, { state: location.state });
    // } else {
    //   // Go to the default (english) version of the current page.
    //   const regex = new RegExp('/fr/');
    //
    //   navigate(location.pathname.replace(regex, '/'), { state: location.state });
    // }
  }

  handleGoToTopButtonClick() {
    // window.scrollTo(0, 0);

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const {
      location,
      children,
      displaySearchInHeader,
      locale,
      authUser,
      contactPageLanguageChange,
      pageSlugs,
      is404Page,
    } = this.props;

    const { displayGoToTopButton } = this.state;

    // let microsoftDropdownClasses;
    let sapDropdownClasses;
    let aboutUsDropdownClasses;
    let joinUsDropdownClasses;
    // let languageDropdownClasses;

    // let displayedLanguage;
    // let languageChange;
    // let displayedLanguageChange;
    //
    // if (locale === 'en') {
    //   displayedLanguage = 'English';
    //   languageChange = 'fr';
    //   displayedLanguageChange = 'French';
    // } else {
    //   displayedLanguage = 'Français';
    //   languageChange = 'en';
    //   displayedLanguageChange = 'Anglais';
    // }

    // const microsoftPatternRegex = new RegExp('/microsoft/');
    // const sapPatternRegex = new RegExp('/sap/');
    // const aboutUsPatternRegex = new RegExp('/qui-sommes-nous/');
    // const profilePatternRegex = new RegExp('/app/');
    // const joinUsRegex = new RegExp('/rejoignez-nous/');
    // const languageDropdownClasses = 'ml-lg-3 pt-4 pt-lg-0 pb-4 pb-lg-0';

    // Add classes to different dropdowns based on current path.
    aboutUsDropdownClasses = 'pt-4 pt-lg-2 pb-4 pb-lg-2';

    allPatterns['about-us'].forEach((pattern) => {
      if (new RegExp(pattern).test(location.pathname)) {
        aboutUsDropdownClasses = 'desktop-underline pt-4 pt-lg-2 pb-4 pb-lg-2';
      }
    });

    sapDropdownClasses = 'pt-4 pt-lg-2 pb-4 pb-lg-2';

    allPatterns['sap'].forEach((pattern) => {
      if (new RegExp(pattern).test(location.pathname)) {
        sapDropdownClasses = 'desktop-underline pt-4 pt-lg-2 pb-4 pb-lg-2';
      }
    });

    // microsoftDropdownClasses = 'pt-4 pt-lg-2 pb-4 pb-lg-2';
    //
    // allPatterns['microsoft'].forEach((pattern) => {
    //   if (new RegExp(pattern).test(location.pathname)) {
    //     microsoftDropdownClasses = 'desktop-underline pt-4 pt-lg-2 pb-4 pb-lg-2';
    //   }
    // });

    joinUsDropdownClasses = 'pt-4 pt-lg-2 pb-4 pb-lg-2';

    allPatterns['join-us'].forEach((pattern) => {
      if (new RegExp(pattern).test(location.pathname)) {
        joinUsDropdownClasses = 'desktop-underline pt-4 pt-lg-2 pb-4 pb-lg-2';
      }
    });

    // // To have a dynamic right margin when displaying or not the search part.
    // if (displaySearchInHeader) {
    //   languageDropdownClasses = 'mobile-border-bottom ml-lg-3 desktop-mr-12 pt-4 pt-lg-0 pb-4 '
    //     + 'pb-lg-0';
    // } else {
    //   languageDropdownClasses = 'ml-lg-3 pt-4 pt-lg-0 pb-4 pb-lg-0';
    // }

    // const isProfilePath = profilePatternRegex.test(location.pathname);

    return (
      // TODO: Create sub components
      // TODO: Improve accessibility scores
      <IntlProvider locale={locale} messages={jsonFilesText[locale]}>
        <div>
          <header className="header-position header-z-index">
            <Navbar
              bg="dark"
              variant="dark"
              expand="lg"
              className="header-z-index header-special-shadow"
            >
              <Container>
                <LocalizedLink to="/" className="navbar-brand ml-2 width-9 py-0">
                  {/* <Navbar.Brand href="/"> */}
                  {/* <img alt={title} src={logoDatArt} width="150"
                        className="ml-4 h-auto" /> */}
                  {/* <StaticQuery */}
                  {/*  query={query} */}
                  {/*  render={({ site }) => ( */}
                  <img
                    src={datArtLogo}
                    alt="DatArt Logo"
                    width="100%"
                    className="h-auto"
                  />
                  {/*  )} */}
                  {/* /> */}
                  {/* </Navbar.Brand> */}
                </LocalizedLink>
                {/* <Navbar.Toggle className="mr-5" aria-controls="main-navbar" /> */}
                <Navbar.Toggle
                  className="ml-3 ml-sm-0 mr-1 border-0 navbar-toggle"
                  // data-toggle="collapse"
                  // data-target="#navbar"
                  aria-controls="main-navbar"
                >
                  {/* <span className="sr-only">Toggle Navigation</span> */}
                  <span id="toggle-first-bar" className="icon-bar" />
                  <span id="toggle-second-bar" className="icon-bar" />
                  <span id="toggle-third-bar" className="icon-bar" />
                </Navbar.Toggle>
                <Navbar.Collapse id="main-navbar" className="mr-lg-1">
                  {/* <Nav className="ml-auto mr-4"> */}
                  <Nav className="w-menu ml-auto mr-auto mr-lg-0 text-center position-relative">
                    {/* <Nav.Link href="#home">Home</Nav.Link> */}
                    {/* <Link to="/" className="nav-link"
                        activeClassName="active">Home</Link> */}
                    <LocalizedLink
                      to="/"
                      className="nav-link mobile-border-bottom pt-4 pt-lg-2 pb-4 pb-lg-2"
                      activeClassName="active desktop-underline"
                    >
                      <FormattedMessage id="navBarHome" />
                    </LocalizedLink>
                    {/* <Link to="/sap/" className="nav-link"
                    activeClassName="active">SAP</Link> */}
                    {/* <NavDropdown */}
                    {/*  title="SAP" */}
                    {/*  id="sap-dropdown" */}
                    {/*  className={sapDropDownMenuActiveClass} */}
                    {/* > */}
                    {/*  /!* <Link *!/ */}
                    {/*  /!* to="/sap/companies/" *!/ */}
                    {/*  /!* className="dropdown-item" *!/ */}
                    {/*  /!* activeClassName="active" *!/ */}
                    {/*  /!* getProps={({ isPartiallyCurrent }) => (isPartiallyCurrent *!/ */}
                    {/*  /!* ? { className: 'active' } : null)} *!/ */}
                    {/*  /!* > *!/ */}
                    {/*  /!* Companies *!/ */}
                    {/*  /!* </Link> *!/ */}
                    {/*  /!* <Link to="/sap/companies/" className="dropdown-item" */}
                    {/*  activeClassName="active"> *!/ */}
                    {/*  /!* Companies *!/ */}
                    {/*  /!* </Link> *!/ */}
                    {/*  <LocalizedLink */}
                    {/*    to="/sap/companies/" */}
                    {/*    className="dropdown-item" */}
                    {/*    activeClassName="active" */}
                    {/*  > */}
                    {/*    <FormattedMessage id="navBarSAPCompanies" /> */}
                    {/*  </LocalizedLink> */}
                    {/*  /!* <Link to="/sap/solutions/" className="dropdown-item" */}
                    {/*  activeClassName="active"> *!/ */}
                    {/*  /!* Solutions *!/ */}
                    {/*  /!* </Link> *!/ */}
                    {/*  <LocalizedLink */}
                    {/*    to="/sap/solutions/" */}
                    {/*    className="dropdown-item" */}
                    {/*    activeClassName="active" */}
                    {/*  > */}
                    {/*    <FormattedMessage id="navBarSAPSolutions" /> */}
                    {/*  </LocalizedLink> */}
                    {/*  /!* <Link *!/ */}
                    {/*  /!* to="/sap/development/" *!/ */}
                    {/*  /!* className="dropdown-item" *!/ */}
                    {/*  /!* activeClassName="active" *!/ */}
                    {/*  /!* > *!/ */}
                    {/*  /!* Development *!/ */}
                    {/*  /!* </Link> *!/ */}
                    {/*  <LocalizedLink */}
                    {/*    to="/sap/development/" */}
                    {/*    className="dropdown-item" */}
                    {/*    activeClassName="active" */}
                    {/*  > */}
                    {/*    <FormattedMessage id="navBarSAPDevelopment" /> */}
                    {/*  </LocalizedLink> */}
                    {/* </NavDropdown> */}
                    <Dropdown as={NavItem} className="mobile-border-bottom">
                      <Dropdown.Toggle
                        as={NavLink}
                        id=""
                        className={aboutUsDropdownClasses}
                      >
                        <FormattedMessage id="navBarAboutUsTitle" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="mb-5 mb-lg-0">
                        <LocalizedLink
                          to="/qui-sommes-nous/presentation-datart/"
                          className="dropdown-item py-3 py-lg-1"
                          activeClassName="active"
                        >
                          <FormattedMessage id="navBarAboutUsPresentation" />
                        </LocalizedLink>
                        <LocalizedLink
                          to="/qui-sommes-nous/culture-entreprise/"
                          className="dropdown-item py-3 py-lg-1"
                          activeClassName="active"
                        >
                          <FormattedMessage id="navBarAboutUsBusinessCulture" />
                        </LocalizedLink>
                        <LocalizedLink
                          to="/qui-sommes-nous/certifications/"
                          className="dropdown-item py-3 py-lg-1"
                          activeClassName="active"
                        >
                          <FormattedMessage id="navBarAboutUsCertifications" />
                        </LocalizedLink>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as={NavItem} className="mobile-border-bottom">
                      <Dropdown.Toggle
                        as={NavLink}
                        id="sap-dropdown"
                        className={sapDropdownClasses}
                      >
                        <FormattedMessage id="navBarSAPTitle" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="mb-5 mb-lg-0">
                        {/* <LocalizedLink */}
                        {/*  to="/sap/expertise/" */}
                        {/*  className="dropdown-item py-3 py-lg-1" */}
                        {/*  activeClassName="active" */}
                        {/* > */}
                        {/*  Expertise */}
                        {/* </LocalizedLink> */}
                        {/* <Dropdown.Divider /> */}
                        {/* <Dropdown.Header className="text-info font-weight-bold font-size-1"> */}
                        {/*  Services */}
                        {/* </Dropdown.Header> */}
                        <LocalizedLink
                          to="/sap/services/conseil-formation/"
                          className="dropdown-item py-3 py-lg-1"
                          activeClassName="active"
                        >
                          <FormattedMessage id="navBarSAPConsulting" />
                        </LocalizedLink>
                        <LocalizedLink
                          to="/sap/services/gestion-projets/"
                          className="dropdown-item py-3 py-lg-1"
                          activeClassName="active"
                        >
                          <FormattedMessage id="navBarSAPProjectManagement" />
                        </LocalizedLink>
                        <LocalizedLink
                          to="/sap/services/maintenance/"
                          className="dropdown-item py-3 py-lg-1"
                          activeClassName="active"
                        >
                          <FormattedMessage id="navBarSAPMaintenance" />
                        </LocalizedLink>
                        {/* <LocalizedLink */}
                        {/*  to="/sap/development/" */}
                        {/*  className="dropdown-item py-3 py-lg-1" */}
                        {/*  activeClassName="active" */}
                        {/* > */}
                        {/*  <FormattedMessage id="navBarSAPDevelopment" /> */}
                        {/* </LocalizedLink> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <Link */}
                    {/* to="/microsoft/" */}
                    {/* className="nav-link" */}
                    {/* activeClassName="active" */}
                    {/* > */}
                    {/* Microsoft */}
                    {/* </Link> */}
                    {/* <NavDropdown */}
                    {/*  title="Microsoft" */}
                    {/*  id="microsoft-dropdown" */}
                    {/*  className={microsoftDropDownMenuActiveClass} */}
                    {/* > */}
                    {/*  <LocalizedLink */}
                    {/*    to="/microsoft/services/" */}
                    {/*    className="dropdown-item" */}
                    {/*    activeClassName="active" */}
                    {/*  > */}
                    {/*    <FormattedMessage id="navBarMicrosoftServices" /> */}
                    {/*  </LocalizedLink> */}
                    {/*  <LocalizedLink */}
                    {/*    to="/microsoft/competencies/" */}
                    {/*    className="dropdown-item" */}
                    {/*    activeClassName="active" */}
                    {/*  > */}
                    {/*    <FormattedMessage id="navBarMicrosoftCompetencies" /> */}
                    {/*  </LocalizedLink> */}
                    {/*  <LocalizedLink */}
                    {/*    to="/microsoft/partners/" */}
                    {/*    className="dropdown-item" */}
                    {/*    activeClassName="active" */}
                    {/*  > */}
                    {/*    <FormattedMessage id="navBarMicrosoftPartners" /> */}
                    {/*  </LocalizedLink> */}
                    {/* </NavDropdown> */}
                    {/* <Dropdown as={NavItem} className="mobile-border-bottom"> */}
                    {/*  <Dropdown.Toggle */}
                    {/*    as={NavLink} */}
                    {/*    id="microsoft-dropdown" */}
                    {/*    className={microsoftDropdownClasses} */}
                    {/*  > */}
                    {/*    <FormattedMessage id="navBarMicrosoftTitle" /> */}
                    {/*  </Dropdown.Toggle> */}
                    {/*  <Dropdown.Menu className="mb-5 mb-lg-0"> */}
                    {/*    <LocalizedLink */}
                    {/*      to="/microsoft/conseil-formation/" */}
                    {/*      className="dropdown-item py-3 py-lg-1" */}
                    {/*      activeClassName="active" */}
                    {/*    > */}
                    {/*      <FormattedMessage id="navBarMicrosoftConsulting" /> */}
                    {/*    </LocalizedLink> */}
                    {/*    <LocalizedLink */}
                    {/*      to="/microsoft/gestion-projets/" */}
                    {/*      className="dropdown-item py-3 py-lg-1" */}
                    {/*      activeClassName="active" */}
                    {/*    > */}
                    {/*      <FormattedMessage id="navBarMicrosoftProjectManagement" /> */}
                    {/*    </LocalizedLink> */}
                    {/*    <LocalizedLink */}
                    {/*      to="/microsoft/maintenance/" */}
                    {/*      className="dropdown-item py-3 py-lg-1" */}
                    {/*      activeClassName="active" */}
                    {/*    > */}
                    {/*      <FormattedMessage id="navBarMicrosoftMaintenance" /> */}
                    {/*    </LocalizedLink> */}
                    {/*  </Dropdown.Menu> */}
                    {/* </Dropdown> */}
                    {/* <Link */}
                    {/* to="/location/" */}
                    {/* className="nav-link" */}
                    {/* activeClassName="active" */}
                    {/* > */}
                    {/* Location */}
                    {/* </Link> */}

                    {/* <LocalizedLink */}
                    {/*  to="/location/" */}
                    {/*  className="nav-link mobile-border-bottom pt-4 pt-lg-2 pb-4 pb-lg-2" */}
                    {/*  activeClassName="active desktop-underline" */}
                    {/* > */}
                    {/*  <FormattedMessage id="navBarLocation" /> */}
                    {/* </LocalizedLink> */}

                    {/* <Link */}
                    {/* to="/contact/" */}
                    {/* className="nav-link" */}
                    {/* activeClassName="active" */}
                    {/* > */}
                    {/* Contact */}
                    {/* </Link> */}
                    <Dropdown as={NavItem} className="mobile-border-bottom">
                      <Dropdown.Toggle
                        as={NavLink}
                        id="join-us-dropdown"
                        className={joinUsDropdownClasses}
                      >
                        <FormattedMessage id="navBarJoinUsTitle" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="mb-5 mb-lg-0">
                        <LocalizedLink
                          to="/rejoignez-nous/freelance/"
                          className="dropdown-item py-3 py-lg-1"
                          activeClassName="active"
                        >
                          <FormattedMessage id="navBarJoinUsFreelancer" />
                        </LocalizedLink>
                        <LocalizedLink
                          to="/rejoignez-nous/candidature-spontanee/"
                          className="dropdown-item py-3 py-lg-1"
                          activeClassName="active"
                        >
                          <FormattedMessage id="navBarJoinUsUnsolicitedApplication" />
                        </LocalizedLink>
                      </Dropdown.Menu>
                    </Dropdown>
                    <LocalizedLink
                      to="/contact/"
                      className="nav-link mobile-border-bottom pt-4 pt-lg-2 pb-4 pb-lg-2"
                      activeClassName="active desktop-underline"
                    >
                      <FormattedMessage id="navBarContact" />
                    </LocalizedLink>
                    {/* <NavDropdown */}
                    {/*  title={jsonFilesText[locale].displayedLanguage} */}
                    {/*  id="language-dropdown" */}
                    {/*  onSelect={this.handleNavDropdownLanguageSelect} */}
                    {/* > */}
                    {/*  <NavDropdown.Item */}
                    {/*    eventKey={jsonFilesText[locale].languageChange} */}
                    {/*  > */}
                    {/*    <Navbar.Text className="mr-2"> */}
                    {/*      {locale === 'en' ? ( */}
                    {/*        <ReactCountryFlag code="fr" svg /> */}
                    {/*      ) : ( */}
                    {/*        <ReactCountryFlag code="gb" svg /> */}
                    {/*      )} */}
                    {/*    </Navbar.Text> */}
                    {/*    {jsonFilesText[locale].displayedLanguageChange} */}
                    {/*  </NavDropdown.Item> */}
                    {/*  /!* <NavDropdown.Item *!/ */}
                    {/*  /!* href="#action/3.1" *!/ */}
                    {/*  /!* eventKey="french-language" *!/ */}
                    {/*  /!* > *!/ */}
                    {/*  /!* French *!/ */}
                    {/*  /!* </NavDropdown.Item> *!/ */}
                    {/* </NavDropdown> */}
                    {!is404Page && (
                      <Dropdown
                        as={NavItem}
                        onSelect={this.handleNavDropdownLanguageSelect}
                        alignRight
                      >
                        <Dropdown.Toggle
                          as={NavLink}
                          id="language-dropdown"
                          className="ml-lg-3 pt-4 pt-lg-2 pb-4 pb-lg-2"
                        >
                          {typeof (locale) !== 'undefined' && locales[locale].display}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="selector-min-width mb-5 mb-lg-0">
                          {
                            Object.keys(locales)
                              .filter((lang) => lang !== locale)
                              .map((lang) => (
                                <Dropdown.Item
                                  key={locales[lang].path}
                                  eventKey={locales[lang].path}
                                  className="py-3 py-lg-1 text-center"
                                >
                                  {locales[lang].locale}
                                </Dropdown.Item>
                              ))
                          }
                          {/* <Dropdown.Item */}
                          {/*  eventKey={jsonFilesText[locale].languageChange} */}
                          {/*  className="py-3 py-lg-1 text-center" */}
                          {/* > */}
                          {/*  {jsonFilesText[locale].displayedLanguageChange} */}
                          {/* </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    {/* <Dropdown> */}
                    {/* <Dropdown.Toggle as={LanguageToggle} id="dropdown-search" /> */}
                    {/* <Dropdown.Menu as={LanguageMenu} alignRight /> */}
                    {/* </Dropdown> */}
                    {/* <Button className="border-0" variant="outline-light"> */}
                    {/* <FontAwesomeIcon icon={faSearch} /> */}
                    {/* </Button> */}

                    {/* {displaySearchInHeader && ( */}
                    {/*  <SearchMenu /> */}
                    {/* )} */}

                  </Nav>
                  {/* <Nav className="mb-2 mt-2 ml-auto text-center"> */}
                  {/*  {displaySearchInHeader && ( */}
                  {/*    <Form className="ml-lg-4 nav-item d-flex align-items-center"> */}
                  {/*      <FormControl */}
                  {/*        className="test" */}
                  {/*        placeholder={jsonFilesText[locale].searchInputHint} */}
                  {/*        onChange="" */}
                  {/*        // value="" */}
                  {/*      /> */}
                  {/*      <Button */}
                  {/*        className="border-0 shadow-none nav-item" */}
                  {/*        variant="outline-light" */}
                  {/*        onClick="" */}
                  {/*        active="" */}
                  {/*      > */}
                  {/*        <FontAwesomeIcon icon={faSearch} /> */}
                  {/*      </Button> */}
                  {/*    </Form> */}
                  {/*  )} */}
                  {/* </Nav> */}
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </header>
          {/* <main className="bg-white p-4">{children}</main> */}
          <main className="min-vh-100">{children}</main>
          <footer className="bg-dark text-light">
            {/* <Container className="px-4 pt-4 pb-3"> */}
            <Container className="px-3 px-lg-5 py-3">
              <Row>
                <Col xs={6}>
                  <p className="mb-0">
                    DatArt SA
                    {' '}
                    <FontAwesomeIcon icon={faCopyright} />
                    {' '}
                    {new Date().getFullYear()}
                  </p>
                  <p className="ml-5 mt-2">
                    <OverlayTrigger
                      placement="top"
                      overlay={(
                        <Tooltip id="linkedin-tooltip">
                          LinkedIn
                        </Tooltip>
                      )}
                    >
                      <a
                        id="linkedin-link"
                        className="mr-3"
                        href="https://ch.linkedin.com/company/datartsa/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="linkedin"
                      >
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          size="lg"
                          color="#ffffff"
                        />
                      </a>
                    </OverlayTrigger>
                  </p>
                </Col>
                <Col xs={6} className="text-right">
                  <p className="mb-0 d-inline-block text-center">
                    <LocalizedLink to="/contact/#location" className="underline-animation">
                      <FormattedMessage id="footerAddress" />
                    </LocalizedLink>
                    <span className="font-weight-bold">
                      <br />
                      Grand-Rue 28
                      <br />
                      1095 Lutry
                    </span>
                  </p>
                </Col>
                {/* <Col xs={6} className="text-right"> */}
                {/*  {authUser === null ? ( */}
                {/*    <LocalizedLink */}
                {/*      to="/app/login/" */}
                {/*      id="admin-link" */}
                {/*      className="text-light" */}
                {/*      activeClassName="active-link" */}
                {/*    > */}
                {/*      <FormattedMessage id="navBarAdmin" /> */}
                {/*    </LocalizedLink> */}
                {/*  ) : ( */}
                {/*    <Dropdown alignRight> */}
                {/*      <Dropdown.Toggle */}
                {/*        id="connected-dropdown" */}
                {/*        className="shadow-none" */}
                {/*      > */}
                {/*        {isProfilePath ? ( */}
                {/*          <FontAwesomeIcon icon={faUserCircle} size="lg" /> */}
                {/*        ) : ( */}
                {/*          <FontAwesomeIcon icon={faUser} size="lg" /> */}
                {/*        )} */}
                {/*      </Dropdown.Toggle> */}
                {/*      <Dropdown.Menu> */}
                {/*        <LocalizedLink */}
                {/*          to="/app/profile/" */}
                {/*          className="dropdown-item py-3 py-lg-1" */}
                {/*          activeClassName="active" */}
                {/*        > */}
                {/*          <FormattedMessage id="navBarMyProfile" /> */}
                {/*        </LocalizedLink> */}
                {/*        <LocalizedLink */}
                {/*          to="/app/files/" */}
                {/*          className="dropdown-item py-3 py-lg-1" */}
                {/*          activeClassName="active" */}
                {/*        > */}
                {/*          <FormattedMessage id="navBarFiles" /> */}
                {/*        </LocalizedLink> */}
                {/*        <LocalizedLink */}
                {/*          to="/app/calendar/" */}
                {/*          className="dropdown-item py-3 py-lg-1" */}
                {/*          activeClassName="active" */}
                {/*        > */}
                {/*          <FormattedMessage id="navBarCalendar" /> */}
                {/*        </LocalizedLink> */}
                {/*        <Dropdown.Divider /> */}
                {/*        <Navbar.Text className="dropdown-item-text"> */}
                {/*          <LogoutButton location={location} /> */}
                {/*        </Navbar.Text> */}
                {/*      </Dropdown.Menu> */}
                {/*    </Dropdown> */}
                {/*  )} */}
                {/* </Col> */}
              </Row>
              {/* <Row className="mb-4"> */}
              {/* <Row> */}
              {/*  <Col xs={6}> */}
              {/*    DataArt */}
              {/*    {' '} */}
              {/*    <FontAwesomeIcon icon={faCopyright} /> */}
              {/*    {' '} */}
              {/*    {new Date().getFullYear()} */}
              {/*  </Col> */}
              {/*  /!* <Col xs={6} className="text-right"> *!/ */}
              {/*  /!*  <FormattedMessage id="footerMadeWith"/> *!/ */}
              {/*  /!*  {' '} *!/ */}
              {/*  /!*  <a *!/ */}
              {/*  /!*    href="https://www.gatsbyjs.org/" *!/ */}
              {/*  /!*    target="_blank" *!/ */}
              {/*  /!*    rel="noopener noreferrer" *!/ */}
              {/*  /!*    className="underline-animation" *!/ */}
              {/*  /!*  > *!/ */}
              {/*  /!*    GatsbyJS *!/ */}
              {/*  /!*  </a> *!/ */}
              {/*  /!*  <FontAwesomeIcon *!/ */}
              {/*  /!*    className="ml-1 text-primary" *!/ */}
              {/*  /!*    icon={faExternalLinkAlt} *!/ */}
              {/*  /!*    style={{ fontSize: '0.4em' }} *!/ */}
              {/*  /!*  /> *!/ */}
              {/*  /!* {authUser === null ? ( *!/ */}
              {/*  /!*  <Link *!/ */}
              {/*  /!*    to="/app/login/" *!/ */}
              {/*  /!*    className="text-light" *!/ */}
              {/*  /!*    activeClassName="active" *!/ */}
              {/*  /!*  > *!/ */}
              {/*  /!*    Admin *!/ */}
              {/*  /!*  </Link> *!/ */}
              {/*  /!* ) : ( *!/ */}
              {/*  /!*  <Link *!/ */}
              {/*  /!*    to="/app/profile/" *!/ */}
              {/*  /!*    className="text-light" *!/ */}
              {/*  /!*    activeClassName="active" *!/ */}
              {/*  /!*  > *!/ */}
              {/*  /!*    {authUser.email} *!/ */}
              {/*  /!*  </Link> *!/ */}
              {/*  /!* )} *!/ */}
              {/*  /!* <Link *!/ */}
              {/*  /!*  to="/app/login/" *!/ */}
              {/*  /!*  className="text-light" *!/ */}
              {/*  /!*  activeClassName="active" *!/ */}
              {/*  /!* > *!/ */}
              {/*  /!*  Admin *!/ */}
              {/*  /!* </Link> *!/ */}
              {/*  /!* </Col> *!/ */}
              {/* </Row> */}
              {/* <Row> */}
              {/*  <Col xs={12} className="text-right"> */}
              {/*     eslint-disable-next-line */}
              {/*    <Link*/}
              {/*      to={location.pathname} */}
              {/*      className="text-light" */}
              {/*      activeClassName="active" */}
              {/*      replace */}
              {/*      state={location.state} */}
              {/*    > */}
              {/*      <FontAwesomeIcon icon={faAngleDoubleUp} /> */}
              {/*    </Link> */}
              {/*  </Col> */}
              {/* </Row> */}
            </Container>
            {displayGoToTopButton && (
              <Button
                variant="outline-primary"
                onClick={this.handleGoToTopButtonClick}
                className="position-fixed top-button border-0 shadow-none"
              >
                <FontAwesomeIcon icon={faAngleDoubleUp} />
              </Button>
            )}
          </footer>
        </div>
      </IntlProvider>
    );
  }
}

LayoutBase.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  displaySearchInHeader: PropTypes.bool,
  authUser: PropTypes.object,
  pageSlugs: PropTypes.arrayOf(Object),
  contactPageLanguageChange: PropTypes.func,
  is404Page: PropTypes.bool,
};

LayoutBase.defaultProps = {
  displaySearchInHeader: true,
  contactPageLanguageChange: undefined,
  is404Page: false,
};

// const Layout = withSession(LayoutBase);
const Layout = LayoutBase;

export default Layout;
